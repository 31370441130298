"use strict";

window.addEventListener('DOMContentLoaded', () => {
  //MENU
  const toggleButton = document.querySelector('.c-header__toggle');
  const bodyLock = document.body;
  const nav = document.querySelector('.c-nav');

  toggleButton.addEventListener('click', function() {
    toggleButton.classList.toggle('is-toggled')
    bodyLock.classList.toggle('is-locked');
    nav.classList.toggle('is-active');
  });
  //FAQ 
  const faqQuestion = document.querySelectorAll('.c-faq__question');
  const faqAnswerWrapp = document.querySelectorAll('.c-faq__answer-wrapper');
  const faqAnswer = document.querySelectorAll('.c-faq__answer');
  const hideFaq = function () {
    faqAnswerWrapp.forEach((answer, number) => {
      answer.style.height = 0;
      faqAnswerWrapp[number].classList.remove('is-opened');
      faqQuestion[number].classList.remove('is-rotated');
    });
  };
  const showFaq = function (number) {
    faqAnswerWrapp[number].style.height = `${faqAnswer[number].clientHeight + 20}px`;
    faqAnswerWrapp[number].classList.add('is-opened');
    faqQuestion[number].classList.add('is-rotated'); 
  };

  hideFaq();

  faqQuestion.forEach((question, num) => {
    question.addEventListener('click', function () {
      hideFaq();
      showFaq(num);
    });
  });

  //Обтекание картинки
  const txtContainer = document.querySelectorAll('.c-main__txt-content');
  // const img = document.querySelectorAll('.c-main__img');

  txtContainer.forEach((item, i) => {
    const img = item.querySelectorAll('.c-main__img');
    // console.log(img)
    img.forEach((i, number) => {
      if (number === 0) {
        i.classList.add('c-main__img_float-right');
      } else if (number === 1) {
        i.classList.add('c-main__img_float-left');
      } else if (number % 2 === 0) {
        i.classList.add('c-main__img_float-left');
      } else {
        i.classList.add('c-main__img_float-right');
      }
    });
  });
});